import React from 'react';
import HeaderImage from './headerImage';

const Hero = () => (
  <div
    css={(theme) => ({
      margin: '0 auto',
      maxWidth: `${theme.center_width_percent}%`,
      padding: '0 1.0875rem',
      borderBottom: '2px solid #979797',
    })}
  >
    <HeaderImage />
    <div css={(theme) => ({
      margin: '0 auto',
      maxWidth: `${theme.center_width_percent - 0}%`,
      textAlign: 'center',
    })}
    >
      <h1 css={{
        margin: '5px',
        padding: 0,
        fontSize: '1.75em',
        fontWeight: 900,
        letterSpacing: '2px',
      }}
      >
      JAN 24 - 26, 2020
      </h1>
      <h2 css={{
        fontWeight: 400,
        margin: '2px 0 15px 0',
        padding: 0,
      }}
      >
      Charity Boardgaming Weekend
      </h2>
    </div>
  </div>
);

export default Hero;
