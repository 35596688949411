/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { ThemeProvider } from 'emotion-theming';
import ContentPane from './contentPane';

import Header from './header';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          theme {
            light_bg
            light_text
            light_link
            tint
            primary
            accent
            dark_bg
            dark_text
            dark_link
            center_width_percent
          }
          menuLinks {
            name
            link
          }
        }
      }
    }
  `);

  return (
    <>
      <ThemeProvider theme={data.site.siteMetadata.theme}>
        <div css={(theme) => ({
          color: theme.light_text,
          background: theme.light_bg,
        })}
        >

          <Header
            siteTitle={data.site.siteMetadata.title}
            menuLinks={data.site.siteMetadata.menuLinks}
          />
          <main>{children}</main>
          <ContentPane title="" anchor="copyright" dark>
            <footer>
              ©
              {' '}
              {new Date().getFullYear()}
, CritAid
            </footer>
          </ContentPane>
        </div>
      </ThemeProvider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
