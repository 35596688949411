import PropTypes from 'prop-types';
import React from 'react';
import Hero from './hero';
import NavBar from './navbar';

const Header = ({ siteTitle, menuLinks }) => (
  <header
    css={(theme) => ({
      background: theme.light_bg,
    })}
  >
    <NavBar siteTitle={siteTitle} menuLinks={menuLinks} />
    <Hero />
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
  menuLinks: PropTypes.arrayOf(
    PropTypes.objectOf({
      name: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
};

Header.defaultProps = {
  siteTitle: '',
  menuLinks: [],
};

export default Header;
