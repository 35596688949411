import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { css } from 'emotion';
import styled from '@emotion/styled';
import NavImage from './navImage';

const StyledNav = styled.nav`
    width: 100%;
    background: ${(props) => props.theme.dark_text};
    border-bottom: 3px solid #979797;
    margin: 0 0;
    padding: 0 0;
`;

const StyledUnorderedList = styled.ul`
    display: flex;
    flex: 1 1 0%;
    width: ${(props) => props.theme.center_width_percent}%;
    margin: 0 auto;
    padding: 0;
`;

const MenuItem = styled.li`
  padding: 0;
  margin: 0;
  list-style: none;
  padding: 1rem;
`;

const MenuLink = styled(Link)`
    color: ${(props) => props.theme.light_text};
    margin: 0;
    padding: 0;
    text-decoration: none;
    font-size: 1.25em;
    font-weight: 900;
`;

const NavBar = ({ menuLinks }) => (
  <StyledNav>
    <StyledUnorderedList>
      <div className={css`
        padding: 12px;
        display: inline-block;
        width: 70px;
      `}
      >
        <NavImage />
      </div>
      {menuLinks.map((link) => (
        <MenuItem
          key={link.name}
        >
          <MenuLink to={link.link}>{link.name}</MenuLink>
        </MenuItem>
      ))}
    </StyledUnorderedList>
  </StyledNav>
);

NavBar.propTypes = {
  menuLinks: PropTypes.arrayOf(
    PropTypes.objectOf({
      name: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
};

NavBar.defaultProps = {
  menuLinks: [],
};

export default NavBar;
