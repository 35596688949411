import React from 'react';
import PropTypes from 'prop-types';

const ContentPane = ({ title, anchor, dark, children }) => (
  <div
    id={anchor}
    css={(theme) => ({
      color: dark ? theme.dark_text : theme.light_text,
      background: dark ? theme.dark_bg : theme.light_bg,
      padding: '40px 0',
    })}
  >
    <div css={(theme) => ({
      width: `${theme.center_width_percent}%`,
      margin: '0 auto',
      a: {
        color: dark ? theme.dark_link : theme.light_link,
        fontWeight: 600,
      },
      p: {
        lineHeight: 'normal',
      },
    })}
    >
      <h2 css={{
        fontSize: '1.75em',
        margin: '0 0 40px 0',
      }}
      >
        { title.toUpperCase() }
      </h2>
      { children }
    </div>
  </div>
);

ContentPane.propTypes = {
  title: PropTypes.string,
  anchor: PropTypes.string.isRequired,
  dark: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

ContentPane.defaultProps = {
  title: '',
  dark: false,
};

export default ContentPane;
